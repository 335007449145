import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const set = property => (state, payload) => (state[property] = payload)

export const toggle = property => state => (state[property] = !state[property])

export default new Vuex.Store({
  state: {
    drawer: null,
  },
  mutations: {
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
  },
  actions: {
  },
  modules: {
  }
})
