<template>
  <div id="home">
    <get-a-quote />
    <v-container
      id="goals"
      class="py-5"
      tag="section"
    >
      <heading>Our Services</heading>

      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          class="d-flex"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            outlined
            max-width="100%"
          >
            <v-img :src="feature.src" />
            <v-card-title
              class="align-start"
              style="min-height: 88px;"
              v-text="feature.title"
            />
            <v-card-text
              class="pb-5 pt-3"
              v-text="feature.text"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      id="testimonials"
      class="py-5"
      tag="section"
    >
      <heading>Testimonials</heading>

      <v-row>
        <v-col
          v-for="(testimonial, i) in testimonials"
          :key="i"
          cols="12"
          md="6"
        >
          <testimonial :testimonial="testimonial" />
        </v-col>
      </v-row>
    </v-container>

    <v-container
      id="highlighted-testimonial"
      class="pt-5 px-0 mb-n3"
      fluid
      tag="section"
    >
      <v-parallax
        contain
        src="/static/home-parallax.png"
      >
        <v-overlay
          absolute
          class="px-3"
          opacity=".8"
        >
          <testimonial
            dark
            :testimonial="highlightedTestimonial"
          />
        </v-overlay>
      </v-parallax>
    </v-container>

    <v-container
      id="why-us"
      class="px-3 py-12 mb-n9 grey lighten-3"
      fluid
      tag="section"
    >
      <heading>Subscribe to our <span class="primary--text">Newsletter</span></heading>

      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            hide-details
            label="Email"
            solo
            color="secondary"
            outlined
            single-line
            flat
          >
            <template v-slot:append>
              <v-btn color="primary" >
                Subscribe
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Home',
      meta: [
        { name: 'description', content: 'Customized vue-cli templates for Vue and Vuetify' }
      ]
    },

    components: {
      GetAQuote: () => import('@/components/GetAQuote'),
      Heading: () => import('@/components/Heading'),
      Testimonial: () => import('@/components/Testimonial')
    },

    data: () => ({
      highlightedTestimonial: {
        blurb: 'Chris has been a constant resource in all of my accomplishments.',
        person: 'G.K Chesterson',
        title: 'Authority, On Topics'
      },
      features: [
        {
          title: 'Tree Removal',
          text: 'Create wide-open spaces and re-imagine how your landscape should look. Be done with pine needles once and for all.',
          src: '/static/feature1.png'
        },
        {
          title: 'Stone Driveways',
          text: 'Tired of erosion? We build and maintain stone driveways',
          src: '/static/feature2.png'
        },
        {
          title: 'Pond Creation',
          text: 'If you have always wanted to have a pond, now you can make it happen!',
          src: '/static/feature3.png'
        }
      ],
      testimonials: [
        {
          blurb: 'It is amazing to watch Chris work. He accomplishes impressive feats!',
          person: 'Person Persony',
          title: 'President, Some Company'
        },
        {
          blurb: 'So thankful for the Arendt Construction team. They changed my yard for the better!',
          person: 'Someone Super',
          title: 'Mayor, Of a Town'
        }
      ]
    })
  }
</script>
